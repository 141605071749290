<template>
	<w-dialog max-width="60%" :value="model" :title="dialogTitle" @close="$emit('close')">
		<v-stepper v-model="step">
			<v-stepper-header>
				<template v-for="n in steps">
					<v-stepper-step :key="`${n}-step`" :class="{ pointer: step > n }" :complete="step > n" :step="n" @click="move(n)"></v-stepper-step>
					<w-divider v-if="n < steps" :key="n" />
				</template>
			</v-stepper-header>
			<v-stepper-content step="1">
				<w-layout row wrap>
					<v-subheader v-t="'general_information'" />
				</w-layout>
				<w-layout pl-4 row wrap>
					<w-flex grow pr-1>
						<v-text-field
							v-model="catalogService.name"
							autofocus
							:label="$t('offers.service_title') + '*'"
							maxlength="191"
							type="text"
							@keydown.enter="catalogService.name ? (step = 2) : (step = 1)"
						/>
					</w-flex>
					<w-flex pl-1 shrink>
						<w-checkbox v-model="catalogService.is_private" :label="$t('offers.is_private')" />
					</w-flex>
				</w-layout>
				<w-layout pl-4 row wrap>
					<w-flex pr-1 xs6>
						<w-currency-input v-model="catalogService.price" :label="$t('price')" />
					</w-flex>
					<w-flex pl-1 xs6>
						<w-duration-input v-model="catalogService.time_allocation" :label="$t('offers.time_allocation')" />
					</w-flex>
				</w-layout>
				<w-layout row wrap>
					<v-subheader v-t="'offers.commercial_description'" />
				</w-layout>
				<w-layout pl-3>
					<w-flex>
						<div>
							<w-rich-text-editor
								:key="`${catalogService.id}-commercial-description-textarea`"
								v-model="catalogService.commercial_description"
								mode="advanced"
								:uuid="catalogService.id + '-commercial'"
								style="max-height: 300px; overflow-y: scroll"
							></w-rich-text-editor>
						</div>
					</w-flex>
				</w-layout>
				<w-layout row wrap>
					<v-spacer />
					<w-btn :disabled="!isFirstStepValid" flat @click="step = 2">{{ $t('next') }}</w-btn>
				</w-layout>
			</v-stepper-content>
			<v-stepper-content step="2">
				<w-layout column>
					<v-subheader v-t="'offers.contractual_description'" />
				</w-layout>
				<w-layout pl-3>
					<w-flex>
						<div>
							<w-rich-text-editor
								v-model="catalogService.contractual_description"
								mode="advanced"
								:uuid="catalogService.id + '-contractual'"
								style="max-height: 300px; overflow-y: scroll"
							></w-rich-text-editor>
						</div>
					</w-flex>
				</w-layout>
				<w-layout row wrap>
					<v-spacer />
					<w-btn flat @click="step = 3">{{ $t('next') }}</w-btn>
				</w-layout>
			</v-stepper-content>
			<v-stepper-content step="3">
				<w-flex xs10 offset-xs-1>
					<w-layout row wrap>
						<v-subheader>{{ $t('deliverable') + ' *' }}</v-subheader>
					</w-layout>
					<w-layout pl-4 row wrap>
						<w-flex>
							<v-select
								v-model="catalogService.deliverable"
								autofocus
								:items="deliverables"
								item-text="name"
								item-value="id"
								:label="$t('deliverable_type')"
								:loading="deliverablesLoading"
								return-object
							/>
						</w-flex>
					</w-layout>
					<w-layout row wrap>
						<v-subheader>{{ $t('offers.frequencies.inits_name') + ' *' }}</v-subheader>
					</w-layout>
					<!-- Frequency init -->
					<w-layout align-center pl-4 row wrap>
						<w-flex mr-1 shrink>
							<w-text-input v-model="catalogService.task_creation_date_value" class="mt-0" hide-details min="1" type="number" />
						</w-flex>
						<w-flex ml-1 shrink>
							<v-select
								v-model="catalogService.task_creation_date_unit"
								hide-details
								item-value="id"
								:items="durations"
								:loading="durationsLoading"
								return-object
							>
								<template v-slot:item="{ item }">
									{{ $tc(`offers.frequencies.task_creation_date.${item.name}`, catalogService.task_creation_date_value) }}
								</template>
								<template v-slot:selection="{ item }"
									>{{ $tc(`offers.frequencies.task_creation_date.${item.name}`, catalogService.task_creation_date_value) }}
								</template>
							</v-select>
						</w-flex>
					</w-layout>
				</w-flex>
				<w-layout row wrap>
					<v-spacer />
					<w-btn :disabled="!isThirdStepValid" flat @click="step = 4">{{ $t('next') }}</w-btn>
				</w-layout>
			</v-stepper-content>
			<v-stepper-content step="4">
				<w-layout column>
					<w-flex v-t="'offers.task_creation_explained'" subheading />
					<TasksList height="300" :tasks="tasks" @input="tasks = $event" @update-tasks="updateTasks" />
					<w-layout row>
						<v-spacer />
						<w-btn flat @click="method">{{ $t('actions.validate') }}</w-btn>
					</w-layout>
				</w-layout>
			</v-stepper-content>
		</v-stepper>
	</w-dialog>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'CatalogServiceWizard',
	components: {
		TasksList: () => ({
			component: import('@/components/Offers/TasksList')
		})
	},
	mixins: [OffersModuleGuard],
	props: {
		category: {
			required: true,
			type: Object
		},
		model: {
			default: false,
			type: Boolean
		},
		value: {
			default: () => ({}),
			required: false,
			type: Object
		},
		new: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			test: false,
			catalogService: {},
			durationsLoading: false,
			deliverables: [],
			deliverablesLoading: false,
			durations: [],
			printPrice: '',
			step: 1,
			steps: 4,
			tasks: []
		}
	},
	computed: {
		dialogTitle: function () {
			if (this.catalogService?.id && !this.new) {
				return this.$t('offers.update_service')
			}
			return this.$t('offers.create_service')
		},
		isFirstStepValid: function () {
			return this.catalogService.name
		},
		isThirdStepValid: function () {
			return this.catalogService.deliverable_id && this.catalogService.task_creation_date_value && this.catalogService.task_creation_date_unit_id
		},
		method: function () {
			if (this.catalogService.id && !this.new) {
				return this.update
			} else {
				return this.save
			}
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (newVal) {
				if (newVal) {
					this.catalogService = newVal.clone()
					if (Array.isArray(this.catalogService.task_models)) {
						this.tasks = [...this.catalogService.task_models]
					} else {
						this.tasks = []
					}
				} else {
					this.catalogService.task_creation_date_value = 1
					this.tasks = []
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.getDeliverables()
		this.listDurations()
		setTimeout(() => {
			this.test = true
		}, 5000)
	},
	methods: {
		getDeliverables: function () {
			this.deliverablesLoading = true
			return this.service
				.listDeliverables()
				.then(res => {
					this.deliverables = res
				})
				.finally(() => {
					this.deliverablesLoading = false
				})
		},
		listDurations: function () {
			this.durationsLoading = true
			return this.service
				.listDurations()
				.then(durations => {
					this.durations = durations
					if (!this.catalogService.task_creation_date_unit_id) {
						this.catalogService.task_creation_date_unit = this.durations[0]
					}
				})
				.finally(() => {
					this.durationsLoading = false
				})
		},
		move: function (i) {
			if (i < this.step) {
				this.step = i
			}
		},
		save: function () {
			this.catalogService.catalog_service_category_id = this.category.id
			this.catalogService.task_models = this.tasks.filter(task => task.name.trim() !== '')
			this.catalogService.task_models.forEach(taskModel => this.deleteTaskModelType(taskModel))
			this.service.createCatalogService(this.accountingFirmId, this.category.id, this.catalogService).then(res => {
				this.$emit('add', res)
				this.$emit('close')
			})
		},
		update: function () {
			this.catalogService.task_models = this.tasks.filter(task => task.name.trim() !== '')
			this.catalogService.task_models.forEach(taskModel => this.deleteTaskModelType(taskModel))
			this.service.updateCatalogService(this.accountingFirmId, this.category.id, this.catalogService).then(res => {
				this.$emit('replace', res)
				this.$emit('close')
			})
		},
		deleteTaskModelType: function (taskModel) {
			if (taskModel.type) {
				delete taskModel.type
			}
		},
		updateTasks: function (newValue) {
			this.tasks = newValue
		}
	}
}
</script>
<style>
#quill-container {
	max-height: 300px;
	overflow-y: scroll;
}

.a_gauche .v-label--active {
	left: -20% !important;
}
</style>
